import { Component } from 'react';
import { cloneDeep, isUndefined } from 'lodash';
import './App.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

interface IEntry {
  name: string;
  numbers: number[];
  numbersCorrect: number;
}

interface IAppState {
  allNumbers: number[];
  leftOverNumbers: number[];
  drawnNumbers: number[];
  entries: IEntry[];
  entryName: string;
  entryNumbers: string;
  entryEditIndex?: number;
  editEntryNumbers: string;
  winners: IEntry[];
  lastDrawnNumber?: number;
  removeUserConfirmationDialogOpen: boolean;
  userToRemove?: IEntry;
}

export class App extends Component<any, IAppState> {
  entryStorageKey = 'entries';
  unsortedNumbers = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90];

  constructor(props: any) {
    super(props);

    const numbers = this.shuffle(this.unsortedNumbers);

    let initialEntries: IEntry[] = [];
    const storedEntries = localStorage.getItem(this.entryStorageKey);

    if (storedEntries != null) {
      try {
        initialEntries = JSON.parse(storedEntries);
      } catch (e) {
        // just in case there was an error parsing the entries in storage
        //
        initialEntries = [];
      }
      
    }

    this.state = {
      allNumbers: cloneDeep(numbers),
      leftOverNumbers: cloneDeep(numbers),
      drawnNumbers: [],
      entries: initialEntries,
      entryName: '',
      entryNumbers: '',
      entryEditIndex: undefined,
      editEntryNumbers: '',
      winners: [],
      lastDrawnNumber: undefined,
      removeUserConfirmationDialogOpen: false,
      userToRemove: undefined,
    }
  }

  updateEntryInputValues = ({ target }: any) => {
    const value: any = { [target.name]: target.value };
    this.setState({ ...value });
  }

  addEntry = () => {
    const splittedEntryNumbers = this.state.entryNumbers.split(',').map((entryNumber) => parseInt(entryNumber, 10));

    const entriesClone = cloneDeep(this.state.entries);

    let numbersAlreadyDrawn = 0;
    splittedEntryNumbers.forEach((entryNumber) => {
      if (this.state.drawnNumbers.includes(entryNumber)) {
        numbersAlreadyDrawn += 1;
      }
    });

    entriesClone.push({
      name: this.state.entryName,
      numbers: splittedEntryNumbers,
      numbersCorrect: numbersAlreadyDrawn,
    });

    this.setState({
      entries: entriesClone,
      entryName: '',
      entryNumbers: '',
    }, () => {
      localStorage.setItem(this.entryStorageKey, JSON.stringify(this.state.entries));
    });
  }

  setEditCurrentEntry = (index: number) => {
    const currentEntryNumbers = this.state.entries[index].numbers.join(',');

    this.setState({
      entryEditIndex: index,
      editEntryNumbers: currentEntryNumbers,
    });
  }

  updateCurrentEntry = () => {
    if (!isUndefined(this.state.entryEditIndex)) {
      const currentEntriesClone = cloneDeep(this.state.entries);

      const updatedNumbers = this.state.editEntryNumbers.split(',').map((entryNumber) => parseInt(entryNumber, 10));

      currentEntriesClone[this.state.entryEditIndex].numbers = updatedNumbers;

      let numbersAlreadyDrawn = 0;
      updatedNumbers.forEach((entryNumber) => {
        if (this.state.drawnNumbers.includes(entryNumber)) {
          numbersAlreadyDrawn += 1;
        }
      });

      currentEntriesClone[this.state.entryEditIndex].numbersCorrect = numbersAlreadyDrawn;
  
      this.setState({
        entryEditIndex: undefined,
        editEntryNumbers: '',
        entries: currentEntriesClone,
      }, () => {
        localStorage.setItem(this.entryStorageKey, JSON.stringify(this.state.entries));
      });
    }
  }

  drawNumber = () => {
    var randomIndex = Math.floor(Math.random() * this.state.leftOverNumbers.length);
    var drawnNumber = this.state.leftOverNumbers[randomIndex];

    const updatedDrawnNumbers = cloneDeep(this.state.drawnNumbers);

    updatedDrawnNumbers.push(drawnNumber);

    const updatedLeftOverNumbers = cloneDeep(this.state.leftOverNumbers);

    updatedLeftOverNumbers.splice(randomIndex, 1);

    const foundWinners: IEntry[] = [];
    const updatedEntries: IEntry[] = [];

    this.state.entries.forEach((entry) => {
      if (entry.numbers.every((entryNumber) => updatedDrawnNumbers.includes(entryNumber))) {
        foundWinners.push(entry);
      }
      let numbersAlreadyDrawn = 0;
      entry.numbers.forEach((entryNumber) => {
        if (updatedDrawnNumbers.includes(entryNumber)) {
          numbersAlreadyDrawn += 1;
        }
      });
      // since name and numbers correct are primitives we don't have to clone them as they will be
      // not referenced. numbers is an array so it will be by reference and we make its own reference for it
      //
      updatedEntries.push({
        name: entry.name,
        numbers: cloneDeep(entry.numbers),
        numbersCorrect: numbersAlreadyDrawn,
      });
    });

    this.setState({
      drawnNumbers: updatedDrawnNumbers,
      leftOverNumbers: updatedLeftOverNumbers,
      lastDrawnNumber: drawnNumber,
      winners: foundWinners,
      entries: updatedEntries,
    })
  }

  // origin: http://sedition.com/perl/javascript-fy.html
  // This is a Fisher-Yates shuffle
  //
  shuffle = (arr: number[]) => {
    var currentIndex = arr.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = arr[currentIndex];
      arr[currentIndex] = arr[randomIndex];
      arr[randomIndex] = temporaryValue;
    }

    return arr;
  }

  reset = () => {
    const numbers = this.shuffle(this.unsortedNumbers);

    const entriesClone = cloneDeep(this.state.entries);

    entriesClone.forEach((entryClone) => {
      entryClone.numbersCorrect = 0;
    });

    this.setState({
      allNumbers: cloneDeep(numbers),
      leftOverNumbers: cloneDeep(numbers),
      drawnNumbers: [],
      entries: entriesClone,
      winners: [],
      lastDrawnNumber: undefined,
    }, () => {
      localStorage.setItem(this.entryStorageKey, JSON.stringify(this.state.entries));
    });
  }

  removeCurrentEntry = () => {
    const newEntries = cloneDeep(this.state.entries);
    const newWinners = cloneDeep(this.state.entries);

    if (this.state.userToRemove) {
      const indexToRemove = newEntries.findIndex((entry) => entry.name === (this.state.userToRemove as IEntry).name)
      newEntries.splice(indexToRemove, 1);

      const winnerIndex = newWinners.findIndex((winner) => winner.name === (this.state.userToRemove as IEntry).name)
  
      if (winnerIndex > -1) {
        newWinners.splice(winnerIndex, 1);
      }
    }

    this.setState({
      removeUserConfirmationDialogOpen: false,
      entries: newEntries,
      winners: newWinners
    }, () => {
      localStorage.setItem(this.entryStorageKey, JSON.stringify(this.state.entries));
    });
  }

  toggleRemoveUserDialog = (entry?: IEntry) => {
    this.setState({
      removeUserConfirmationDialogOpen: !this.state.removeUserConfirmationDialogOpen,
      userToRemove: entry,
    });
  }


  render() {
    const lastDrawnNumberText = this.state.lastDrawnNumber ? `Laatste getrokken nummer is ${this.state.lastDrawnNumber}` : 'Er is nog geen nummer getrokken';
    const winnerText = this.state.winners.length === 0 ? 'Er heeft nog niemand gewonnen' : `${this.state.winners.map((winner) => winner.name).join(', ')}`;
    return (
      <div className="App">
        <div className="header">
          <img className="logo" src={'./radio-noordzij-logo.png'} aria-label="radio noordzij logo"/>
          <h1>BINGO</h1>
        </div>
        <div className="content">
          <div className="numbers-grid">
            <div className="all-numbers">
              { this.state.allNumbers.map((allNumber) => {
                return <p key={`all-numbers-${allNumber}`} aria-label={`${allNumber} ${this.state.drawnNumbers.includes(allNumber) ? 'is al getrokken' : ''} ${this.state.leftOverNumbers.includes(allNumber) ? 'is nog niet getrokken' : ''}`} className={`number ${this.state.drawnNumbers.includes(allNumber) ? 'is-drawn' : ''} ${this.state.leftOverNumbers.includes(allNumber) ? 'is-left-over' : ''}`}>{ allNumber }</p>
              })}
            </div>
            <div className="buttons">
              <Button className="button reset-button" variant="contained" aria-label="Reset het veld" onClick={() => this.reset()}>Reset het veld</Button>
            </div>
          </div>
          <div className="entries">
            <div className="current-entries">
              <h2 aria-label="Laatst getrokken nummer">Laatst getrokken nummer</h2>
              <p className="drawn-number" aria-label={lastDrawnNumberText}>
                <span className="last-drawn-number">{ lastDrawnNumberText }</span>
                <Button disabled={this.state.leftOverNumbers.length === 0} className={`button draw-button ${this.state.leftOverNumbers.length === 0 ? 'disabled': ''}`} variant="contained" aria-label="Trek een nummer" onClick={() => this.drawNumber()}>Trek een nummer</Button>
              </p>
              <h2 aria-label="Deelnemer die heeft gewonnen">Deelnemer die heeft gewonnen</h2>
              <p aria-label={winnerText}>{ winnerText }</p>
              <h2 aria-label="Tussenstand">Tussenstand</h2>
              <div className="highscores">{
                cloneDeep(this.state.entries).sort((a, b) => b.numbersCorrect - a.numbersCorrect).slice(0, 15).map((highscoreEntry, index) => {
                  return <p key={`highscore-${highscoreEntry.name}-${highscoreEntry.numbersCorrect}`} className="highscore" aria-label={`${highscoreEntry.name} staat op plaats ${index + 1} met ${highscoreEntry.numbersCorrect} getallen goed`}>
                    <span>{highscoreEntry.name}</span>
                    <span className="highscore-count">{highscoreEntry.numbersCorrect}</span>
                  </p>
                })
              }</div>
            </div>
          </div>
        </div>
        <div className="user-overview">
          <h2>Huidige Deelnemers</h2>
          <div className="entries">
            {this.state.entries.length === 0 ? <p>Er zijn nog geen deelnemers toegevoegd</p>: ''}
            { this.state.entries.map((entry, index) => {
              return <div key={`'${entry.name}'`} className="current-entry">
                <p aria-label={`'Deelnemer ${entry.name}'`} className="name">{entry.name}</p>
                {
                  this.state.entryEditIndex === index ?
                    <div className="current-entry-edit">
                        <TextField
                          name="editEntryNumbers"
                          label={`Bijwerken van deelnemers nummers ${entry.name} (komma gescheiden)`}
                          variant="outlined"
                          aria-label={`Bijwerken van deelnemers nummers ${entry.name} (komma gescheiden)`}
                          value={this.state.editEntryNumbers}
                          onChange={this.updateEntryInputValues}
                          type="text"
                          className="input"
                          margin="normal"
                          multiline
                          rows={4}
                        />
                        <Button className="button" variant="contained" aria-label="Bewaar de bijgewerkte nummers" onClick={() => this.updateCurrentEntry()}>Bewaar de bijgewerkte nummers</Button>
                    </div>
                  : <p className="entry-numbers">{entry.numbers.map((entryNumber) => {
                    return <span key={`${entry.name}-number-${entryNumber}`} aria-label={`${entryNumber} ${this.state.drawnNumbers.includes(entryNumber) ? 'is al getrokken' : ''}${this.state.leftOverNumbers.includes(entryNumber) ? 'is nog niet getrokken' : ''}`} className={`number ${this.state.drawnNumbers.includes(entryNumber) ? 'is-drawn' : ''} ${this.state.leftOverNumbers.includes(entryNumber) ? 'is-left-over' : ''}`}>{entryNumber}</span>
                  })}<Button className="button edit-button" variant="contained" aria-label={`Bewerk de getallen van ${entry.name}`} onClick={() => this.setEditCurrentEntry(index)}><EditIcon/></Button><Button className="button remove-button" variant="contained" aria-label={`Verwijder ${entry.name} van de deelnemers`} onClick={() => this.toggleRemoveUserDialog(entry)}><DeleteIcon/></Button></p>
                }
                
              </div>
            })}
          </div>
          <div className="entry-input">
            <h2 aria-label="Nieuwe gebruiker">Nieuwe Gebruiker</h2>
            <TextField
              name="entryName"
              label="Naam"
              variant="outlined"
              aria-label="Naam van de nieuwe deelnemer"
              value={this.state.entryName}
              onChange={this.updateEntryInputValues}
              type="text"
              className="input"
              margin="normal"
            />
            <TextField
              name="entryNumbers"
              label="Deelnemersnummers (komma gescheiden)"
              variant="outlined"
              aria-label="Deelnemersnummers (komma gescheiden)"
              value={this.state.entryNumbers}
              onChange={this.updateEntryInputValues}
              type="text"
              className="input"
              margin="normal"
              multiline
              rows={4}
            />
            <Button variant="contained" aria-label={`Voeg ${this.state.entryName} toe"`} onClick={() => this.addEntry()}>Voeg de deelnemer toe</Button>
          </div>
        </div>
        <Dialog
            className="base-dialog"
            open={ this.state.removeUserConfirmationDialogOpen }>
              <DialogContent className="warning-modal">
                <DialogContentText aria-label={`Weet je zeker dat je ${this.state.userToRemove?.name} wilt verwijderen?`}>Weet je zeker dat je {this.state.userToRemove?.name} wilt verwijderen?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.toggleRemoveUserDialog()} color="secondary" aria-label="Nee behoud deze gebruiker">
                  Nee behoud deze gebruiker
                </Button>
                <Button onClick={() => this.removeCurrentEntry()} color="secondary" autoFocus aria-label={`Ja verwijder ${this.state.userToRemove?.name}`}>
                  Ja verwijder {this.state.userToRemove?.name}
                </Button>
              </DialogActions>
          </Dialog>
      </div>
    );
  }
}

export default App;
